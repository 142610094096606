import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../context/Context';
import { device } from '../../device';

const Wrapper = styled.div`
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    padding-top: ${(props) => props.theme.grid.divider_2};
    padding-left: 216px;
    padding-right: ${(props) => props.theme.grid.divider_4};
    padding-bottom: 200px;
    z-index: 1000;
    position: relative;
    ${({ open }) =>
        !open &&
        `
		padding-left: 96px;
	`};
    @media ${device.laptop} {
        padding-left: ${(props) => props.theme.grid.divider_2};
        padding-right: ${(props) => props.theme.grid.divider_2};
    }
`;

const Container = ({ children, className }) => {
    const { sideBarOpen } = useContext(AppContext);
    return (
        <Wrapper className={className} open={sideBarOpen}>
            {children}
        </Wrapper>
    );
};

export default Container;
